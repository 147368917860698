import React from "react"
//import {Link} from "gatsby"
import settings from "../data/settings";


const Header = ({ siteTitle }) => (
  <header>
        <nav>
            <div className="col"><span>{settings.site_signature}</span></div>
            <div className="col"><span id="figure" className={'figure'}>fig. <span>1</span>, <span>2</span> et <span>3</span></span></div>
        </nav>
  </header>
)

export default Header
